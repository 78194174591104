<script>
import moment from "moment";

export default {
  name: "document2024",
  props: {
    info: Object
  },
  computed: {
    options() {
      console.log(this.info.optionInfo);
      return [
        '癫痫病',
        '严重心律失常、心肌梗死、心力衰竭、器质性',
        '精神分裂症、重度抑郁症等严重精神疾病',
        '严重视力障碍或 严重听力障碍',
        '帕金森病(中重度)、震颤麻痹、阿尔茨海默',
        '脑卒中后遗症、重症肌无力等影响肢体活动的',
        '有酒精依赖的病史',
        '严重贫血、肾功能衰竭、眩晕症、癔病、一型',
        '曾有吸食、注射毒品的行为',
      ].map((label, index) => ({
        label,
        value: this.info.optionInfo ? this.info.optionInfo.split('、')[index] : ''
      }))
    }
  },
  methods: {
    moment
  }
}
</script>

<template>
  <div class="health-agreement">
    <h2>驾驶员健康承诺书</h2>
    <p>本人<span class="fill_in">{{ info.driverName || `&nbsp;&nbsp;&nbsp;&nbsp;` }}</span>，身份证号码：<span
        class="fill_in">{{
        info.idCardNumber || `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`
      }}</span>
      ，现如实申报本人健康情况（含家族病史），具体如下：
    </p>
    <ul>
      <li>
        <div>序号</div>
        <div>疾病名称</div>
        <div>选择本人或家族其他成员是否有对应病史</div>
      </li>
      <li v-for="(vm,index) in options" :key="vm.label">
        <div>{{ index + 1 }}</div>
        <div>{{ vm.label }}</div>
        <div>
          <div class="btn" :class="{active:vm.value==='1'}">有</div>
          <div class="btn" :class="{active:vm.value==='0'}">无</div>
        </div>
      </li>
    </ul>
    <p style="margin: 20px 0">
      本人在此郑重承诺:本人的身体健康情况满足《机动车驾驶证申领和使用规定》、《广州市网络预约出租汽车经营服务管理暂行办法》以及其他法律法规中关于驾驶员的要求，上述填报情况属实，并知悉本人瞒报将产生的法律后果及责任。若因本人瞒报以上病情或虚假陈述造成本人或者其他人身体健康(包括生命)、财产受到损害，本人自愿承担全部责任。为确保身体状况符合驾驶要求，本人将加强自身身体健康管理并定期进行健康体检；如本人身体新增不适，本人承诺将在第一时间暂停营运，同时告知贵单位相关情况。
      特此承诺。</p>
    <p style="text-align: right">承诺人签名:
      <img v-if="info.signaturePhotoUrl" style="max-width:100%;height:35px;margin-top: -5px"
           :src="info.signaturePhotoUrl" alt="">
      <span v-else class="fill_in">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>
    <p style="text-align: right;margin-bottom: 20px">日期:<span class="fill_in">{{
        moment(info.safeSignatureTime).format('YYYY')
      }}</span>年<span class="fill_in">{{ moment(info.safeSignatureTime).format('MM') }}</span>月<span
        class="fill_in">{{ moment(info.safeSignatureTime).format('DD') }}</span>日</p>
  </div>
</template>

<style scoped lang="scss">
.health-agreement {
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  text-align: center;
  padding: 20px 16px;
  background-color: #fff;

  h2 {
    font-weight: 600;
    font-size: 17px;
    margin: 10px 0 20px 0;
    color: #333333;
  }

  p {
    text-align: left;
    color: #333333;
  }

  .fill_in {
    color: #7D7D7D;
    text-decoration: underline;
  }

  ul {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin-top: 12px;
  }

  li {
    display: flex;
    border-bottom: 1px solid #DDDDDD;

    & > div {
      border-right: 1px solid #ddd;
      padding: 5px;

      &:first-child {
        width: 120px;
      }

      &:nth-child(2) {
        flex: 1;
      }

      &:last-child {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn {
      width: 31px;
      height: 26px;
      background: #EDF5FF;
      border-radius: 5px;

      &:first-child {
        margin-right: 12px;
      }

      &:active, &.active {
        background-color: #0062E6;
        color: #fff;
      }
    }
  }
}
</style>
